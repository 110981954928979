import { CalendarIcon } from "@heroicons/react/24/solid";
import { Clock } from "client/components/icons/clock";
import Link from "next/link";
import { useResizeDetector } from "react-resize-detector";
import SimpleButton from "../Button";
import ContainedElement from "../ContainedElement";
import { formatGoogleCalendarLinkForCollection } from "@/client/lib/helpers";

interface DropCard {
  imageUrl: string;
  projectName: string;
  artistName: string;
  currentInvocations: number;
  maxInvocations: number;
  mintStart: string;
  aspectRatio: string;
  platform: string;
  collectionIdOrSlug: string;
}

const DropCard = ({
  imageUrl,
  projectName,
  artistName,
  currentInvocations,
  maxInvocations,
  mintStart,
  aspectRatio,
  platform,
  collectionIdOrSlug,
}: DropCard) => {
  const { width, height, ref } = useResizeDetector();

  const soldOut = currentInvocations >= maxInvocations ? true : false;
  const hasStarted = mintStart && new Date(mintStart) < new Date();

  const addToGcalLink = formatGoogleCalendarLinkForCollection({
    collectionName: projectName,
    artistName,
    startsAt: new Date(mintStart),
    slugOrId: collectionIdOrSlug,
  });
  return (
    <div className="drop-card relative overflow-hidden rounded-2xl">
      <Link
        href={`/collections/${collectionIdOrSlug}`}
        className={`bg-center block bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 dark:hover:bg-neutral-800 transition duration-700 dark:text-white relative rounded-2xl w-full`}
      >
        <div className="z-10 rounded-2xl relative flex flex-col justify-between h-full w-full overflow-hidden">
          <div
            ref={ref}
            className="w-full h-72 mt-4 flex flex-col items-center justify-center"
          >
            <ContainedElement
              aspectRatio={aspectRatio ? Number(aspectRatio) : 1}
              containerWidth={width}
              containerHeight={height}
              padding={44}
            >
              <img
                className="w-full h-full shadow-xl transform scale-95 hover:scale-100 transition duration-300"
                src={imageUrl}
              />
            </ContainedElement>
          </div>
          <div className="p-8 pt-4">
            <h3 className="text-2xl line-clamp-1 text-neutral-900 dark:text-white font-medium tracking-tighter mb-1">
              {projectName}
            </h3>
            <p className="text-lg line-clamp-1 text-neutral-600 dark:text-neutral-400 mb-4">
              {artistName}
            </p>
            <PlatformBadge platform={platform} />

            <div className="mt-4">
              <div className="flex items-center justify-between mb-3">
                {hasStarted ? (
                  <>
                    {soldOut ? (
                      <div className="text-neutral-900 dark:text-neutral-300 flex items-center gap-1">
                        <div className="w-3 h-3 relative mr-2">
                          <span className="block w-3 h-3 absolute bg-green-500 rounded-full animate-ping"></span>
                          <span className="block w-3 h-3 absolute bg-green-500 rounded-full"></span>
                        </div>
                        <p>Mint complete</p>
                      </div>
                    ) : (
                      <div className="text-neutral-900 dark:text-neutral-300 flex items-center gap-1">
                        <div className="w-3 h-3 relative mr-2">
                          <span className="block w-3 h-3 absolute bg-yellow-500 rounded-full animate-ping"></span>
                          <span className="block w-3 h-3 absolute bg-yellow-500 rounded-full"></span>
                        </div>
                        <p>Minting open</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-neutral-900 dark:text-neutral-300 flex items-center gap-1">
                    <Clock />
                    <p>{mintStart ? stringToDropDate(mintStart) : null}</p>
                  </div>
                )}

                <p className="text-neutral-900 dark:text-neutral-300">
                  {currentInvocations} /{" "}
                  {maxInvocations > 10000 ? "10K+" : maxInvocations}
                </p>
              </div>
              <BasicStatusBar
                percentComplete={(currentInvocations / maxInvocations) * 100}
              />
            </div>
          </div>
        </div>
      </Link>
      {!hasStarted ? (
        <div className="z-10 drop-card__actions transition-all bg-neutral-200 dark:bg-neutral-800 absolute left-0 w-full bottom-0 -bottom-20 p-3 py-4 rounded-b-xl">
          <a href={addToGcalLink} rel="noreferrer" target="_blank">
            <SimpleButton
              onClick={() => console.log("")}
              icon={<CalendarIcon className="h-4 w-4 mr-2" />}
              className="justify-center w-full py-3"
            >
              Add to calendar
            </SimpleButton>
          </a>
        </div>
      ) : null}
    </div>
  );
};

const stringToDropDate = (date: string) => {
  const newDate = new Date(date);
  const options = {
    weekday: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  } as any;
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(newDate);
  return formattedDate;
};

const PlatformBadge = ({ platform }: { platform: string }) => {
  let knownPlatform = platformToName(platform) === "Unknown" ? false : true;

  if (!knownPlatform) return <div className="h-[28px]"></div>;

  return (
    <div className="text-neutral-900 dark:bg-neutral-700 dark:text-white inline-block bg-white rounded-full p-1 pl-2 pr-3">
      <div className="flex items-center gap-2 h-full w-full">
        <PlatformIcon platform={platform} />
        <p className="text-sm">{platformToName(platform)}</p>
      </div>
    </div>
  );
};

const platformToName = (platform: string) => {
  switch (platform) {
    case "ART_BLOCKS":
      return "Art Blocks";
    case "GM_STUDIO":
      return "gm.studio";
    case "GEN_ART":
      return "GEN.ART";
    case "VERTICAL_CRYPTO_ART":
      return "VCA";
    default:
      return "Unknown";
  }
};

const PlatformIcon = ({
  platform,
  sizeClasses = "h-4 w-4 rounded-full",
}: {
  platform: string;
  sizeClasses?: string;
}) => {
  switch (platform) {
    case "ART_BLOCKS":
      return <img src="logos/artblocks.png" className={`${sizeClasses}`} />;
    case "GM_STUDIO":
      return <img src="logos/gmdao.jpeg" className={`${sizeClasses}`} />;
    case "GEN_ART":
      return <img src="logos/genart.jpeg" className={`${sizeClasses}`} />;
    case "VERTICAL_CRYPTO_ART":
      return <img src="logos/vca.jpeg" className={`${sizeClasses}`} />;
    default:
      return null;
  }
};

const BasicStatusBar = ({
  percentComplete = 0,
  bgColor = "bg-neutral-200 dark:bg-neutral-700",
  primaryColor = "bg-neutral-900 dark:bg-white",
}: {
  percentComplete?: number;
  bgColor?: string;
  primaryColor?: string;
}) => {
  return (
    <div
      className={`w-full overflow-hidden h-3 relative rounded-full ${bgColor}`}
    >
      <div
        style={{ width: `${percentComplete}%` }}
        className={`h-3 top-0 left-0 absolute rounded-tl-full rounded-bl-full ${primaryColor}`}
      ></div>
    </div>
  );
};

export default DropCard;
